import React, { Fragment } from 'react';
import { Dialog, Grid, DialogTitle, DialogContent, Button } from "@material-ui/core";

class Props {
  open: boolean | undefined = false;
  title: string | undefined = undefined;
  body?: string | JSX.Element = "";  
  onClose?: (() => void) | undefined = undefined;
  hideCloseBtn?: boolean
}

const MessageDialog: React.FC<Props> = ({
  open,
  title,
  body,  
  onClose,
  hideCloseBtn
}) => {

  const renderCloseBtn = () => {
    if (!hideCloseBtn) {
      return (
        <Fragment>
          <br/>
          <div style={{ marginTop: 40, marginBottom: 20 }}>              
            <Button onClick={onClose} variant="outlined" color="primary">
              Ok
            </Button>
          </div>
        </Fragment>
      );
    }
    
    return <br/>;
  }

  const renderBody = () => {
    if (body) {
      return (
        <DialogContent style={{ textAlign: "center" }}>
          {body}
          <br/>
          {renderCloseBtn()}
        </DialogContent>
      )
    }

    return null;
  }

  return (
    <Dialog open={open ?? false} maxWidth="sm" fullWidth={true} onClose={onClose} aria-labelledby="confirm-dialog-title">
      <Grid container justify="center">
        <Grid item>
          <DialogTitle id="confirm-dialog-title" style={{ textAlign: "center" }}>
            <h3>{title}</h3>
          </DialogTitle>
          {renderBody()}
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default MessageDialog;